import React from 'react'
import './SectionMain.css'
import Slider from '../../../components/Slider/Slider'

const SectionMain = () => {
  return (
    <div className='main-container'>
      <div className='main-content'>
        <Slider />
      </div>
    </div>
  )
}

export default SectionMain