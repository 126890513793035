import React, { useContext } from "react";
import "./Line.css";
import { AppContext } from "../../context/AppContext";

const Line = () => {

  const { mode } = useContext(AppContext);


  return (
    <div className={`line-container ${mode}`}>
      <div className="line-content">
        <div className="line-right-group">
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>

        <div className="line-left-group">
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default Line;
