import React, { useContext, useEffect, useState } from "react";
import "./Slider.css";
import { AppContext } from "../../context/AppContext";
import Lang_tr from "../../Lang/Lang_tr";
import Lang_eng from "../../Lang/Lang_eng";

const Slider = () => {
  const [current, setCurrent] = useState(0);
  const { mode } = useContext(AppContext);
  const { lang } = useContext(AppContext);
  const [language, setLanguage] = useState();

  useEffect(() => {
    if (lang === "tr") {
      setLanguage(Lang_tr);
    } else {
      setLanguage(Lang_eng);
    }
  }, [lang]);

  const nextSlide = () => {
    const sliderItems = document.querySelectorAll(".slider-item");

    sliderItems.forEach((item, idx) => {
      item.classList.remove("slider-item-next");
      if (current === sliderItems.length - 1) {
        setCurrent(0);
      } else {
        setCurrent(current + 1);
      }
      if (idx === current) {
        item.classList.remove("slider-item-active");
        item.classList.add("slider-item-next");
      } else if (idx === current + 1) {
        item.classList.add("slider-item-active");
      } else if (current === sliderItems.length - 1) {
        sliderItems[0].classList.add("slider-item-active");
      } else if (idx === current - 1) {
        item.classList.remove("slider-item-next");
      } else {
        item.classList.remove("slider-item-next");
      }
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 6000);

    return () => clearInterval(interval);
  }, [current]);

  return (
    <div className="slider-container">
      <div className="slider-content">
        <div className="slider">
          <div className="slider-item slider-item-active">
            <img
              src="https://demo.bravisthemes.com/mrittik/wp-content/uploads/2023/04/slider-bg-01.jpg"
              alt=""
            />
            <div className={`slider-item-info ${mode}`}>
              <h1>{language && language.homePage_slider.slide1_title}</h1>
              <span></span>
              <p>{language && language.homePage_slider.slide1_text}</p>
              
            </div>
          </div>

          <div className="slider-item">
            <img
              src="https://demo.bravisthemes.com/mrittik/wp-content/uploads/2023/06/home01-slider-bg-04.jpg"
              alt=""
            />
            <div className={`slider-item-info ${mode}`}>
              <h1>{language && language.homePage_slider.slide2_title}</h1>
              <span></span>
              <p>{language && language.homePage_slider.slide2_text}</p>
            </div>
          </div>

          <div className="slider-item">
            <img
              src="https://demo.bravisthemes.com/mrittik/wp-content/uploads/2023/04/slider-bg-02.jpg"
              alt=""
            />
            <div className={`slider-item-info ${mode}`}>
              <h1>{language && language.homePage_slider.slide3_title}</h1>
              <span></span>
              <p>{language && language.homePage_slider.slide3_text}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
