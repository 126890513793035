import camlik1 from '../assets/projects/BeykozCamlik/camlik1.webp';
import camlik2 from '../assets/projects/BeykozCamlik/camlik2.webp';
import genelMerkez1 from '../assets/projects/BeykozGenelMerkezOfis/1.webp';
import genelMerkez2 from '../assets/projects/BeykozGenelMerkezOfis/2.webp';
import genelMerkez3 from '../assets/projects/BeykozGenelMerkezOfis/3.webp';
import genelMerkez4 from '../assets/projects/BeykozGenelMerkezOfis/4.webp';
import hkVilla1 from '../assets/projects/HkVilla/1.webp';
import Corezone1 from '../assets/projects/Corezone/1.webp';
import Corezone2 from '../assets/projects/Corezone/2.webp';
import Corezone3 from '../assets/projects/Corezone/3.webp';
import Corezone4 from '../assets/projects/Corezone/4.webp';
import Corezone5 from '../assets/projects/Corezone/5.webp';
import Corezone6 from '../assets/projects/Corezone/6.webp';
import Corezone7 from '../assets/projects/Corezone/7.webp';
import Corezone8 from '../assets/projects/Corezone/8.webp';
import Dikili1 from '../assets/projects/Dikili/1.webp';
import Dikili2 from '../assets/projects/Dikili/2.webp';
import Dikili3 from '../assets/projects/Dikili/3.webp';
import Dikili4 from '../assets/projects/Dikili/4.webp';
import Dikili5 from '../assets/projects/Dikili/5.webp';
import Dream1 from '../assets/projects/Dream/1.webp';
import Dream2 from '../assets/projects/Dream/2.webp';
import Dream3 from '../assets/projects/Dream/3.webp';
import Dream4 from '../assets/projects/Dream/4.webp';
import Dream5 from '../assets/projects/Dream/5.webp';
import Dream6 from '../assets/projects/Dream/6.webp';
import Dream7 from '../assets/projects/Dream/7.webp';
import Dream8 from '../assets/projects/Dream/8.webp';
import Loop1 from '../assets/projects/Loop/1.webp';
import Loop2 from '../assets/projects/Loop/2.webp';
import ui1 from '../assets/projects/ui/1.webp';
import ui2 from '../assets/projects/ui/2.webp';
import ui3 from '../assets/projects/ui/3.webp';
import ui4 from '../assets/projects/ui/4.webp';
import luela1 from '../assets/projects/lulea/1.webp';
import luela2 from '../assets/projects/lulea/2.webp';
import luela3 from '../assets/projects/lulea/3.webp';
import luela4 from '../assets/projects/lulea/4.webp';
import luela5 from '../assets/projects/lulea/5.webp';
import luela6 from '../assets/projects/lulea/6.webp';
import luela7 from '../assets/projects/lulea/7.webp';
import Tas1 from '../assets/projects/Tas/1.jpeg';

const ProjectsData = [
    {
        "id": 1,
        "name": "Çamlık Villa",
        "location": "İstanbul, Beykoz",
        "year": "2024",
        "description_tr": "Konut çalışması",
        "description_eng": "Housing project",
        "images" : [
            camlik1,
            camlik2
        ],
        "url": "beykoz-camlik-villa"
    },

    {
        "id": 2,
        "name": " Genel Merkez Ofisi",
        "location": "İstanbul, Beykoz",
        "year": "2023",
        "description_tr": "Konut çalışması",
        "description_eng": "Housing project",
        "images" : [
            genelMerkez1,
            genelMerkez2,
            genelMerkez3,
            genelMerkez4
        ],
        "url": "beykoz-genel-merkez-ofisi-waso-teknolojisi"
    },

    {
        "id": 3,
        "name": "HK Villa",
        "location": "İstanbul, Beykoz",
        "year": "2024",
        "description_tr": "Konut çalışması",
        "description_eng": "Housing project",
        "images" : [
            hkVilla1
        ],
        "url": "beykoz-hk-villa"
    },

    {
        "id": 4,
        "name": "Corezone Fittnes-Aletli Pilates Spor Salonu",
        "location": "İstanbul, Caddebostan",
        "year": "2024",
        "description_tr": "İç dizayn çalışması",
        "description_eng": "Interior design project",
        "images" : [
            Corezone1,
            Corezone2,
            Corezone3,
            Corezone4,
            Corezone5,
            Corezone6,
            Corezone7,
            Corezone8
        ],
        "url": "corezone-spor-salonu"
    },

    {
        "id": 5,
        "name": "Dikili Panorama Sitesi",
        "location": "İzmir",
        "year": "2024",
        "description_tr": "Dış cephe&peyzaj çalışması",
        "description_eng": "Exterior design project",
        "images" : [
            Dikili1,
            Dikili2,
            Dikili3,
            Dikili4,
            Dikili5
        ],
        "url": "izmir-dikili-panorama-sitesi"
    },

    {
        "id": 6,
        "name": "Dream House",
        "location": "Hırvatistan",
        "year": "2023",
        "description_tr": "İç dizayn çalışması",
        "description_eng": "Interior design project",
        "images" : [
            Dream1,
            Dream2,
            Dream3,
            Dream4,
            Dream5,
            Dream6,
            Dream7,
            Dream8
        ],
        "url": "dream-house"
    },

    {
        "id": 7,
        "name": "Loop Butik Hotel",
        "location": "Karaköy/İstanbul",
        "year": "2022",
        "description_tr": "İç dizayn çalışması",
        "description_eng": "Interior design project",
        "images" : [
            Loop1,
            Loop2
        ],
        "url": "loop-butik-hotel"
    },

    {
        "id": 8,
        "name": "U.İ Home",
        "location": "Küçükyalı/İstanbul",
        "year": "2024",
        "description_tr": "İç dizayn çalışması",
        "description_eng": "Interior design project",
        "images" : [
            ui1,
            ui2,
            ui3,
            ui4
        ],
        "url": "ui-home"
    },

    {
        "id": 9,
        "name": "Lulea",
        "location": "Karaköy/ istanbul",
        "year": "2021",
        "description_tr": "İç dizayn çalışması",
        "description_eng": "Interior design project",
        "images" : [
            luela1,
            luela2,
            luela3,
            luela4,
            luela5,
            luela6,
            luela7
        ],
        "url": "lulea"
    },

    {
        "id": 10,
        "name": "Yazlık Taş Ev",
        "location": "Şile /İstanbul",
        "year": "2024",
        "description_tr": "Dış cephe tasarım",
        "description_eng": "Exterior design project",
        "images" : [
            Tas1
        ],
        "url": "yazlik-tas-ev"
    },

];


export default ProjectsData;