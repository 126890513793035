import React, { useContext, useEffect, useRef, useState } from "react";
import "./SectionComment.css";
import { AppContext } from "../../../context/AppContext";
import Lang_tr from "../../../Lang/Lang_tr";
import Lang_eng from "../../../Lang/Lang_eng";
import left from "../../../assets/arrow/left.png";
import right from "../../../assets/arrow/right.png";

const SectionComment = () => {
  const { aboutTreshold, mode } = useContext(AppContext);
  const ref = useRef(null);
  var currentScroll = 0;
  var currentComment = 2;

  const { lang } = useContext(AppContext);
  const [language, setLanguage] = useState();

  useEffect(() => {
    if (lang === "tr") {
      setLanguage(Lang_tr);
    } else {
      setLanguage(Lang_eng);
    }
  }, [lang]);

  useEffect(() => {
    const items = document.querySelectorAll(".sectionComment-item");
    items[currentComment].classList.add("comment-active");
  }, []);

  const handleNext = () => {
    const items = document.querySelectorAll(".sectionComment-item");
    items[currentComment].classList.remove("comment-active");
    currentComment++;
    if (window.innerWidth < 768) {
      currentScroll += items[0].clientWidth + 100;
    } else {
      currentScroll += items[0].clientWidth + 200;
    }
    if (currentComment >= items.length) {
      currentComment = 0;
    }
    if (currentScroll > items[0].clientWidth * (items.length - 2)) {
      if (window.innerWidth < 768) {
        currentScroll = -(items[0].clientWidth + 100) * 2;
      } else {
        currentScroll = -(items[0].clientWidth + 200) * 2;
      }
    }
    items[currentComment].classList.add("comment-active");
    items.forEach((item) => {
      if (currentScroll >= 0) {
        item.style.transform = `translateX(-${currentScroll}px)`;
      } else {
        item.style.transform = `translateX(${-currentScroll}px)`;
      }
    });
  };

  const handlePrev = () => {
    const items = document.querySelectorAll(".sectionComment-item");
    items[currentComment].classList.remove("comment-active");
    currentComment--;
    if (window.innerWidth < 768) {
      currentScroll -= items[0].clientWidth + 100;
    } else {
      currentScroll -= items[0].clientWidth + 200;
    }
    if (currentComment < 0) {
      currentComment = items.length - 1;
    }

    if (currentScroll < -items[0].clientWidth * (items.length - 2)) {
      if (window.innerWidth < 768) {
        currentScroll = (items[0].clientWidth + 100) * 2;
      } else {
        currentScroll = (items[0].clientWidth + 200) * 2;
      }
    }
    items[currentComment].classList.add("comment-active");
    items.forEach((item) => {
      if (currentScroll >= 0) {
        item.style.transform = `translateX(-${currentScroll}px)`;
      } else {
        item.style.transform = `translateX(${-currentScroll}px)`;
      }
    });
  };

  return (
    <div className={`comment-container ${mode}`}>
      <div className="sectionComment-container" ref={ref}>
        <div className="sectionComment-bg-1"></div>
        <div className="sectionComment-bg-2"></div>
        <div className="sectionComment-content">
          <div className="sectionComment-list">
            <div className={`sectionComment-item ${mode}`}>
              <p className="sectionComment-item-text">
                "{language && language.yorumlar1} "
              </p>
              <h1 className="sectionComment-item-name">- M.Y</h1>
            </div>

            <div className={`sectionComment-item ${mode}`}>
              <p className="sectionComment-item-text">
                " {language && language.yorumlar2} "
              </p>
              <h1 className="sectionComment-item-name">-U.İ</h1>
            </div>

            <div className={`sectionComment-item ${mode}`}>
              <p className="sectionComment-item-text">"{language && language.yorumlar3}"</p>
              <h1 className="sectionComment-item-name">- G.A</h1>
            </div>

            <div className={`sectionComment-item ${mode}`}>
              <p className="sectionComment-item-text">
                " {language && language.yorumlar4}"
              </p>
              <h1 className="sectionComment-item-name">- A.D</h1>
            </div>

            <div className={`sectionComment-item ${mode}`}>
              <p className="sectionComment-item-text">"{language && language.yorumlar5}"</p>
              <h1 className="sectionComment-item-name">- H.T</h1>
            </div>
          </div>
          <button
            className="comment-next-button"
            onClick={() => {
              handleNext();
            }}
          >
            <img src={right} alt="" />
          </button>
          <button
            className="comment-prev-button"
            onClick={() => {
              handlePrev();
            }}
          >
            <img src={left} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SectionComment;
