import { createContext, useState } from "react";

export const AppContext = createContext();

export const AppContextProvider = ({ children }) => {


    const [aboutTreshold, setAboutTreshold] = useState(0);
    const [projectTreshold, setProjectTreshold] = useState(0);
    const [contactTreshold, setContactTreshold] = useState(0);
    const [commentTreshold, setCommentTreshold] = useState(0);
    const [mode, setMode] = useState("lightMode");
    const [lang, setLang] = useState(localStorage.getItem("lang") || "tr");

    return (
        <AppContext.Provider value={{
            aboutTreshold,
            setAboutTreshold,
            projectTreshold,
            setProjectTreshold,
            contactTreshold,
            setContactTreshold,
            commentTreshold,
            setCommentTreshold,
            mode,
            setMode,
            lang,
            setLang
        }}>
            {children}
        </AppContext.Provider>
    );
};


