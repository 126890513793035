import React, { useContext, useEffect } from "react";
import "./HomeContainer.css";
import SectionMain from "../SectionMain/SectionMain";
import SectionAbout from "../SectionAbout/SectionAbout";
import SectionProject from "../SectionProjects/SectionProject";
import SectionContact from "../../../components/Footer/SectionContact";
import { AppContext } from "../../../context/AppContext";
import SectionComment from "../SectionComments/SectionComment";
import LangSelection from "../../LangSelection/LangSelection";

const HomeContainer = () => {
  const {
    aboutTreshold,
    contactTreshold,
    projectTreshold,
    commentTreshold,
    mode,
  } = useContext(AppContext);

  useEffect(() => {
    const body = document.querySelector("body");

    console.log(aboutTreshold);
    window.addEventListener("scroll", () => {
      if (mode === "darkMode") {
        if (window.scrollY > aboutTreshold - 200) {
          body.classList.add("about-active");
          body.classList.remove("comment-active");
        } else {
          body.classList.remove("about-active");
        }
      } else {
        body.classList.remove("about-active");
      }
    });

    if (mode === "lightMode") {
      body.classList.remove("about-active");
    }
  }, [aboutTreshold, contactTreshold, projectTreshold, mode]);

  return (
    <div className="homeContainer">
      <SectionMain />
      <SectionAbout />
      <SectionProject />
      <SectionComment />
    </div>
  );
};

export default HomeContainer;
