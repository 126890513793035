import React, { useContext, useEffect, useState } from "react";
import "./About.css";
import { AppContext } from "../../context/AppContext";
import Lang_tr from "../../Lang/Lang_tr";
import Lang_eng from "../../Lang/Lang_eng";
import about1 from "../../assets/about/1.webp";
import about2 from "../../assets/about/2.webp";

const About = () => {
  const { mode, lang } = useContext(AppContext);
  const [language, setLanguage] = useState();

  useEffect(() => {
    if (lang === "tr") {
      setLanguage(Lang_tr);
    } else {
      setLanguage(Lang_eng);
    }
  }, [lang]);

  return (
    <>
      {language && (
        <>
          <div className={`about-container ${mode}`}>
            <div className="about-content">
              <div className="about-main-title">
                <h3>{language.aboutPage.mission}</h3>

                <h1>
                  {language.aboutPage.missionText}
                </h1>
              </div>

              <div className="about-content-img">
                <img
                  src={about1}
                  alt=""
                />
              </div>

              <div className="about-body-text">
                <div className="about-body-text-left">
                  <h1>{language.aboutPage.aboutTitle.title1} </h1>
                  <h1>{language.aboutPage.aboutTitle.title2}</h1>
                </div>

                <div className="about-body-text-right">
                  <p>
                    {language.aboutPage.aboutText}
                  </p>
                </div>
              </div>

              <div className="about-content-img">
                <img
                  src={about2}
                  alt=""
                />
              </div>

              <div className="about-body-text-2">
                <p>
                  <span>{language.aboutPage.aboutText2.span1} </span> {language.aboutPage.aboutText2.p} <span>{language.aboutPage.aboutText2.span2}</span> {" "}
                  <span>{language.aboutPage.aboutText2.span3}</span>
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default About;
