import React, { useContext, useEffect, useState } from "react";
import "./SingleProject.css";
import ProjectsData from "../../data/ProjectsData";
import { AppContext } from "../../context/AppContext";

const SingleProject = () => {
  const [projects, setProjects] = useState(ProjectsData.find((item) => item.url === window.location.href.split("/")[4]));
  const { mode } = useContext(AppContext);

  if (window.location.href.includes("/calismalar/")) {
    document.querySelector("body").style.background = "#000";
  }

  useEffect(() => {
    const projects = document.querySelectorAll(
      ".singleProject-images-list img"
    );

    const handleScroll = () => {
      projects.forEach((project, index) => {
        if (
          window.scrollY >
          projects[index].getBoundingClientRect().top + window.scrollY - 500
        ) {
          projects[index].classList.add("project-item-active");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`singleProject-container ${mode}`}>
      <div className="singleProject-content">
        {projects && (
          <>
            <div className="singleProject-main-image">
              <img src={projects.images[0]} alt="" />
            </div>

            <div className="singleProject-info">
              <div className="singleProject-info-left">
                <h1>Proje ismi</h1>
                <h1>Proje Yılı</h1>
                <h1>Lokasyon</h1>
              </div>

              <div className="singleProject-info-right">
                <h1>{projects.name}</h1>
                <h1>{projects.year}</h1>
                <h1>{projects.location}</h1>
              </div>
            </div>

            <div className="singleProject-description">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
                eget turpis at nisl malesuada gravida. Nullam in nunc nec nisl
                tincidunt ultricies
              </p>
            </div>

            <div className="singleProject-images-list">
                {projects.images.map((image, index) => {
                    return (
                    <img
                        key={index}
                        src={image}
                        alt="Project"
                        className="singleProject-image"
                    />
                    );
                })}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default SingleProject;
