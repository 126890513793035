import React, { useContext, useEffect, useRef, useState } from "react";
import "./Header.css";
import { AppContext } from "../../context/AppContext";
import Lang_tr from "../../Lang/Lang_tr";
import Lang_eng from "../../Lang/Lang_eng";
import close from "../../assets/close/close.png";
import lightMenu from "../../assets/menu/light.png";
import darkMenu from "../../assets/menu/dark.png";
import light from "../../assets/mod/sun.png";
import dark from "../../assets/mod/moon.png";

const Header = () => {
  const { aboutTreshold } = useContext(AppContext);

  const [navActive, setNavActive] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [openQuickContact, setOpenQuickContact] = useState(false);
  const [scroll, setScroll] = useState(0);
  const refContent = useRef(null);
  const { mode, setMode, lang, setLang } = useContext(AppContext);
  const [language, setLanguage] = useState();
  const [modeCheck, setModeCheck] = useState("");

  useEffect(() => {
    if (lang === "tr") {
      setLanguage(Lang_tr);
    } else {
      setLanguage(Lang_eng);
    }
  }, [lang]);

  useEffect(() => {
    setModeCheck(mode);
  }, [mode]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY < aboutTreshold && modeCheck === "darkMode") {
        if (refContent.current) {
          refContent.current.style.backgroundColor = "#131313";
        }
      }
      if (window.scrollY > aboutTreshold && modeCheck === "darkMode") {
        if (refContent.current) {
          refContent.current.style.backgroundColor = "#000";
        }
      }

      if (mode === "lightMode" && refContent.current) {
        refContent.current.style.backgroundColor = "#fff";
      }
    };

    if (mode === "darkMode") {
      window.addEventListener("scroll", handleScroll);
    } else {
      window.removeEventListener("scroll", handleScroll);
    }

    if (mode === "lightMode" && refContent.current) {
      refContent.current.style.backgroundColor = "#fff";
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [aboutTreshold, modeCheck, mode]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > scroll) {
        setNavActive(false);
      } else {
        setNavActive(true);
      }
      setScroll(currentScrollY <= 0 ? 0 : currentScrollY);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scroll]);

  const handleMode = () => {
    const body = document.querySelector("body");
    if (mode === "darkMode") {
      setMode("lightMode");
      body.classList.add("lightMode");
      body.classList.remove("darkMode");
    } else {
      setMode("darkMode");
      body.classList.add("darkMode");
      body.classList.remove("lightMode");
    }
  };

  return (
    <div className="header-container">
      <div
        className={`webMode ${mode}`}
        onClick={() => {
          handleMode();
        }}
      >
        <div className="webMode-swiper">
          <div className="dark">
            <img src={dark} alt="" />
          </div>
          <div className="light">
            <img src={light} alt="" />
          </div>

          <div className={`swiper-button ${mode}`}></div>
        </div>
      </div>
      <div
        className={`mobile-header ${openMenu ? "mobile-header-active" : ""}`}
      >
        <div
          className="mobile-header-close"
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        >
          <img src={close} alt="" />
        </div>

        <div className={`mobile-header-content ${mode}`}>
          <a href="/">Anasayfa</a>
          <a href="/hakkimizda">Hakkımızda</a>
          <a href="/calismalar">Çalışmalar</a>
          <a href="/iletisim">İletişim</a>
        </div>

        <div className="mobile-header-bottom">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Qui, ut
            libero! Sed, aut ab!
          </p>
        </div>
      </div>

      <div
        ref={refContent}
        className={`header-content ${mode} ${navActive ? "header-active" : ""}`}
      >
        <a href="/" className="header-logo">
          <h1>IDesign</h1>
        </a>

        <div className={`header-menu ${mode}`}>
          {language && (
            <>
              <a href="/">{language.header.home}</a>
              <a href="/hakkimizda">{language.header.about}</a>
              <a href="/calismalar">{language.header.projects}</a>
              <a
                onClick={() => {
                  // scroll bottom of the page to show contact form smoothly
                  window.scrollTo(0, document.body.scrollHeight);
                }}
              >
                {language.header.contact}
              </a>
            </>
          )}
        </div>

        <div
          className="header-quick-contact-button"
          onClick={() => {
            setOpenQuickContact(!openQuickContact);
          }}
        >
          <img
            src={
              mode === "darkMode"
                ? lightMenu
                : darkMenu
            }
            alt=""
          />
        </div>

        <div className="language">
          <button
            onClick={() => {
              setLang("tr");
              localStorage.setItem("lang", "tr");
            }}
          >
            tr
          </button>
          <button
            onClick={() => {
              setLang("en");
              localStorage.setItem("lang", "en");
            }}
          >
            uk
          </button>
        </div>

        <div
          className="header-mobile-button"
          onClick={() => {
            setOpenMenu(!openMenu);
          }}
        >
          <img
            src={
              mode === "darkMode"
                ? lightMenu
                : darkMenu
            }
            alt=""
          />
        </div>
      </div>

      <div
        className={`header-quick-contact ${mode} ${
          openQuickContact ? "header-quick-contact-active" : ""
        }`}
      >
        <div
          className="header-quick-contact-close"
          onClick={() => {
            setOpenQuickContact(!openQuickContact);
          }}
        >
          <img src={close} alt="" />
        </div>

        <>
          {language && (
            <div className="header-quick-contact-content">
              <div className="header-quick-contact-logo header-logo">
                <h1>IDesign</h1>
              </div>
              <p>{language.rightWindow.text}</p>
              <div className="header-quick-contact-info-row-1">
                <h3>{language.rightWindow.contact} </h3>
                <p>+90 555 555 55 55</p>
                <p>Bağlar Caddesi, Küçükçekmece</p>
                <p>İstanbul</p>
                <p>ilaydabalci@gmail.com</p>
              </div>
              <div className="header-quick-contact-info-row-2">
                <h3>{language.rightWindow.address} </h3>
                <p>Mecidiyeköy, İstanbul</p>
                <p>Türkiye, 34394</p>
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default Header;
