import React, { useRef, useState, useEffect, useContext } from "react";
import "./SectionAbout.css";
import { AppContext } from "../../../context/AppContext";
import Line from "../../../components/Line/Line";
import Lang_tr from "../../../Lang/Lang_tr";
import Lang_eng from "../../../Lang/Lang_eng";

const SectionAbout = () => {
  const { aboutTreshold, setAboutTreshold } = useContext(AppContext);

  const [threshold, setThreshold] = useState(null);
  const ref = useRef(null);
  const textRef = useRef(null);

  const { lang } = useContext(AppContext);
  const [language, setLanguage] = useState();

  useEffect(() => {
    if (lang === "tr") {
      setLanguage(Lang_tr);
    } else {
      setLanguage(Lang_eng);
    }
  }, [lang]);

  useEffect(() => {
    setAboutTreshold(ref.current.getBoundingClientRect().top);
  }, [setAboutTreshold]);


  const handleScroll = () => {
    const text = document.querySelector(".sectionAbout-text");
    const imageSpan = document.querySelectorAll(
      ".sectionAbout-body-image span"
    );

    if (ref.current) {
      const thresholdValue = ref.current.getBoundingClientRect().top;
      setThreshold(thresholdValue);

      if (window.scrollY > thresholdValue + 600) {
        text.classList.add("sectionAbout-text-active");
      } else {
        text.classList.remove("sectionAbout-text-active");
      }

      if (window.scrollY > thresholdValue + 1500) {
        imageSpan.forEach((span) => {
          span.classList.add("sectionAbout-body-image-active");
        });
      } else {
        imageSpan.forEach((span) => {
          span.classList.remove("sectionAbout-body-image-active");
        });
      }

      if (window.scrollY > textRef.current.getBoundingClientRect().top + 1000) {
        textRef.current.classList.add("sectionAbout-body-text-active");
      } else {
        textRef.current.classList.remove("sectionAbout-body-text-active");
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [threshold, setAboutTreshold, aboutTreshold]);

  return (
    <div className="sectionAbout-container" ref={ref}>
      <Line />
      <>
      {language && (
        <div className="sectionAbout-content">
        <div className="sectionAbout-text">
          <p>
           {language.homePage_mainTitle}
          </p>
        </div>

        <div className="sectionAbout-body">
          <div className="sectionAbout-body-image">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>

          <div className="sectionAbout-body-text" ref={textRef}>
            <p>
              {language.homePage_aboutText}
            </p>
          </div>
        </div>
      </div>
      )}
      </>
      
    </div>
  );
};

export default SectionAbout;
