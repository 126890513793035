import React, { useContext, useEffect, useRef, useState } from "react";
import "./SectionProject.css";
import { AppContext } from "../../../context/AppContext";
import ProjectsData from "../../../data/ProjectsData";
import Lang_tr from "../../../Lang/Lang_tr";
import Lang_eng from "../../../Lang/Lang_eng";

const SectionProject = () => {
  const { aboutTreshold, mode } = useContext(AppContext);
  const [projects, setProjects] = useState(ProjectsData);
  const ref = useRef(null);

  const { lang } = useContext(AppContext);
  const [language, setLanguage] = useState();

  useEffect(() => {
    if (lang === "tr") {
      setLanguage(Lang_tr);
    } else {
      setLanguage(Lang_eng);
    }
  }, [lang]);

  return (
    <div className="sectionProject-container" ref={ref}>
      <div className="sectionProject-content">
        {language && (
          <div className={`sectionProject-stats ${mode}`}>
            <div className="sectionProject-stats-item">
              <h1>100+</h1>
              <p>{language.homePage_projectMiniTitle_1} </p>
            </div>
            <div className="sectionProject-stats-item">
              <h1>50+</h1>
              <p>{language.homePage_projectMiniTitle_2} </p>
            </div>
            <div className="sectionProject-stats-item">
              <h1>10+</h1>
              <p>{language.homePage_projectMiniTitle_3}</p>
            </div>
          </div>
        )}

        <div className="sectionProject-list">
          {projects &&
            projects.map((item) => {
              return (
                <div className="sectionProject-list-item">
                  <img src={item.images[0]} alt="" />
                  <div className="sectionProject-list-item-content">
                    <h1>{item.name}</h1>
                    <p>{lang === "tr" ? item.description_tr : item.description_eng}</p>
                  </div>
                </div>
              );
            })}
        </div>

        {language && (
          <div className={`sectionProject-button ${mode}`}>
            <a href="/calismalar">{language.projectButton}</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionProject;
