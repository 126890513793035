import React, { useState } from "react";
import Line from "./components/Line/Line";
import Header from "./components/Header/Header";
import HomeContainer from "./pages/Home/Container/HomeContainer";
import { Routes, Route } from "react-router-dom";
import SectionContact from "./components/Footer/SectionContact";
import About from "./pages/About/About";
import Projects from "./pages/Projects/Projects";
import ProjectsData from "./data/ProjectsData";
import SingleProject from "./pages/SingleProject/SingleProject";

function App() {
  const [projects, setProjects] = useState(ProjectsData);

  return (
    <div className="App">
      <Header />
      <Line />
      <Routes>
        <Route path="/" element={<HomeContainer />} />
        <Route path="/hakkimizda" element={<About />} />
        <Route path="/calismalar" element={<Projects />} />
        {projects &&
          projects.map((item) => {
            return (
              <Route
                path={`/calismalar/${item.url}`}
                element={<SingleProject />}
              />
            );
          })}
      </Routes>
      <SectionContact />
    </div>
  );
}

export default App;
