const lang_eng = {
  navbar: {
    about: "About",
    projects: "Projects",
    contact: "Contact",
    comment: "Comments",
  },
  header: {
    home: "Home",
    about: "About",
    projects: "Projects",
    contact: "Contact",
  },
  rightWindow: {
    text: "IDESIGN is a full-service design firm that provides architectural services.",
    contact: "Contact",
    address: "Office Address",
  },
  homePage_mainTitle:
    "Every design kneaded with passion and love, combined with the character of the space, results in the emergence of unique and meaningful architectural works.",
  homePage_aboutText:
    "İlayda Balcı, between 2016-2018, studied Interior Design at Kütahya Dumlupınar University Faculty of Fine Arts, and then continued and completed her undergraduate studies in Interior Architecture and Environmental Design at Istanbul Medipol University Faculty of Architecture with English education. Starting her career with MimarK and continuing with Güveli Architecture, she continues her career in interior design with her own IDesign brand and unique designs.",
  homePage_projectMiniTitle_1: "Project",
  homePage_projectMiniTitle_2: "Company",
  homePage_projectMiniTitle_3: "Year",
  project1Text: "housing work",
  project2Text: "housing work",
  project3Text: "housing work",
  project4Text: "interior design work",
  project5Text: "exterior landscape work",
  project6Text: "interior design work",
  project7Text: "interior design work",
  project8Text: "interior design work",
  project9Text: "interior design work",
  project10Text: "exterior design",
  projectButton: "View All Works",
  yorumlar1:
    "I can't find any difference between the three designs shown by Ms. İlayda and the real project, she really does a great job of applying what she says exactly.",
  yorumlar2:
    "I would like to thank Ms. İlayda very much, it is really nice to be able to reflect my thoughts exactly on the project.",
  yorumlar3:
    "There was no problem with the delivery date and the products used, I am really happy to work with someone who can do their job well.",
  yorumlar4:
    "I can't find any difference between the three designs shown by Ms. İlayda and the real project, she really does a great job of applying what she says exactly.",
  yorumlar5:
    "I would like to thank Ms. İlayda very much, it is really nice to be able to reflect my thoughts exactly on the project.",
  contactInfo: {
    title: "Contact",
    website: "Website",
    email: "E-Mail",
    phone: "Phone",
    address: "Address",
    sign1: "© 2021 IDESIGN. All rights reserved",
    sign2: "This website was made by agecnyofmenn.com",
  },
  aboutPage: {
    mission: "Our Mission",
    missionText:
      "Our organizational spirit believes that good architecture occurs where design meets space with passion and love.",
    aboutTitle: {
      title1: "architecture",
      title2: "is where we belong",
    },
    aboutText:
      "Ilayda Balcı studied Interior Design at the Vocational School of Fine Arts at Kütahya Dumlupınar University between 2016 and 2018, and then continued and completed her undergraduate education in English language at the Department of Interior Architecture and Environmental Design at Istanbul Medipol University - Faculty of Architecture. While she started her career at MimarK and continued at Guveli Architecture, she continues her career in the field of interior architecture with her unique designs by establishing her own IDesign brand.",
    aboutText2: {
      span1: "Every day",
      span2: "who come with dreams and",
      p: "we meet people",
      span3: "all our effort is to realize their dreams",
    },
  },
  projectPage: {
    title: {
      span1: "P",
      span2: "R",
      span3: "O",
      span4: "J",
      span5: "E",
      span6: "C",
      span7: "T",
      span8: "S",
    },
    review: "Reviews",
  },

  homePage_slider: {
    slide1_title: "Modern Design",
    slide1_text:
      "Bring your spaces together with modern designs and step into the future.",
    slide2_title: "Aesthetic Design",
    slide2_text:
      "Transform your living spaces with aesthetic design and make every day special.",
    slide3_title: "Comfort",
    slide3_text:
      "Combine aesthetics and comfort together, perfect the life in your home",
  },

  projectsData: {
    project1: {
      desc: "Housing study",
    },
    project2: {
      desc: "Housing study",
    },
    project3: {
      desc: "Housing study",
    },
    project4: {
      desc: "Interior design work",
    },
    project5: {
      desc: "Exterior landscape work",
    },
    project6: {
      desc: "Interior design work",
    },
    project7: {
      desc: "Interior design work",
    },
    project8: {
      desc: "Interior design work",
    },
    project9: {
      desc: "Interior design work",
    },
    project10: {
      desc: "Exterior design",
    },
  },
};

export default lang_eng;
