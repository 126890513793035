import React, { useContext, useEffect, useState } from "react";
import "./Projects.css";
import ProjectsData from "../../data/ProjectsData";
import { AppContext } from "../../context/AppContext";
import Lang_tr from "../../Lang/Lang_tr";
import Lang_eng from "../../Lang/Lang_eng";

const Projects = () => {
  const [projects, setProjects] = useState(ProjectsData);
  const { mode } = useContext(AppContext);
  const { lang } = useContext(AppContext);
  const [language, setLanguage] = useState();

  useEffect(() => {
    if (lang === "tr") {
      setLanguage(Lang_tr);
    } else {
      setLanguage(Lang_eng);
    }
  }, [lang]);



  if (window.location.href.includes("/calismalar")) {
    document.querySelector("body").style.backgroundColor = "#000";
  }

  useEffect(() => {
    const projects = document.querySelectorAll(".project-item");

    const handleScroll = () => {
      projects.forEach((project, index) => {
        if (
          window.scrollY >
          projects[index].getBoundingClientRect().top + window.scrollY - 500
        ) {
          projects[index].classList.add("project-item-active");
        } else {
          projects[index].classList.remove("project-item-active");
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  
  useEffect(() => {
    const titleSpan = document.querySelectorAll(".projects-main-title span");

    titleSpan.forEach((span, index) => {
      setTimeout(() => {
        span.classList.add("projects-main-title-active");
      }, index * 200);
    });
  })

  return (
    <div className={`projects-container ${mode}`}>
      <div className="projects-content">
        {language && (
        <div className="projects-main-title">
          <span><h1>{language.projectPage.title.span1}</h1></span>
          <span><h1>{language.projectPage.title.span2}</h1></span>
          <span><h1>{language.projectPage.title.span3}</h1></span>
          <span><h1>{language.projectPage.title.span4}</h1></span>
          <span><h1>{language.projectPage.title.span5}</h1></span>
          <span><h1>{language.projectPage.title.span6}</h1></span>
          <span><h1>{language.projectPage.title.span7}</h1></span>
          <span><h1>{language.projectPage.title.span8}</h1></span>
        </div>
        )}
        <div className="projects-list">
          {projects &&
            projects.map((item) => {
              return (
                <div className="project-item">
                  <div className="project-image">
                    <img
                      src={item.images[0]}
                      alt="Project"
                    />
                  </div>
                  <div className="project-text">
                    <div className="project-text-right">
                      <h3>{item.name}</h3>
                      <p>{lang === "tr" ? item.description_tr : item.description_eng}</p>
                    </div>

                    <div className="project-text-left">
                      <h3>{item.year}</h3>
                    </div>
                  </div>

                  <a href={`/calismalar/${item.url}`} className="project-item-hover">
                    <h1>{language && language.projectPage.review}</h1>
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Projects;
