import React, { useContext, useEffect, useRef, useState } from "react";
import "./SectionContact.css";
import { AppContext } from "../../context/AppContext";
import Line from "../Line/Line";
import Lang_tr from "../../Lang/Lang_tr";
import Lang_eng from "../../Lang/Lang_eng";

const SectionContact = () => {
  const { setContactTreshold, mode } = useContext(AppContext);
  const ref = useRef(null);
  const { lang } = useContext(AppContext);
  const [language, setLanguage] = useState();

  useEffect(() => {
    if (lang === "tr") {
      setLanguage(Lang_tr);
    } else {
      setLanguage(Lang_eng);
    }
  }, [lang]);

  useEffect(() => {
    setContactTreshold(ref.current.getBoundingClientRect().top);
  }, [setContactTreshold]);

  return (
    <div className={`sectionContact-container ${mode}`} ref={ref}>
      {language && (
        <div className="sectionContact-content">
          <div className="sectionContact-title">
            <h1>{language.contactInfo.title} </h1>
          </div>

          <div className="sectionContact-info">
            <div className="sectionContact-info-item">
              <h3>{language.contactInfo.website}</h3>
              <p>www.idesignmimarlik.com</p>
            </div>
            <div className="sectionContact-info-item">
              <h3>{language.contactInfo.email}</h3>
              <p>ilaydablc3@gmail.com</p>
            </div>
            <div className="sectionContact-info-item">
              <h3>{language.contactInfo.phone}</h3>
              <p>+90 535 084 81 23</p>
            </div>
            <div className="sectionContact-info-item">
              <h3>{language.contactInfo.address}</h3>
              <p>İstanbul, Türkiye</p>
            </div>
          </div>

          <div className="sectionContact-signature">
            <h1>IDESIGN</h1>
            <div>
              <p>{language.contactInfo.sign1}.</p>
              <p>{language.contactInfo.sign2}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SectionContact;
