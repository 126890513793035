const lang_tr = {
  navbar: {
    about: "Hakkımda",
    projects: "Projeler",
    contact: "İletişim",
    comment: "Yorumlar",
  },
  header: {
    home: "Anasayfa",
    about: "Hakkımızda",
    projects: "Çalışmalar",
    contact: "İletişim",
  },

  rightWindow: {
    text: "IDESIGN mimarlık hizmeti veren tam hizmetli bir tasarım firmasıdır.",
    contact: "İletişim",
    address: "Ofis Adresi",
  },
  homePage_mainTitle:
    "Tutku ve aşkla yoğrulan her tasarım, mekanın karakteriyle birleşerek, benzersiz ve anlamlı mimari eserlerin doğmasına neden olur.",
  homePage_aboutText:
    "İlayda Balcı, 2016-2018 yılları arasında Kütahya Dumlupınar Üniversitesinde Güzel Sanatlar Meslek Yüksekokulunda İç  Mekan Tasarımı eğitimi almış daha sonra lisansına İstanbul Medipol Üniversitesi- Mimarlık Fakültesinde  İç Mimarlık ve Çevre Tasarımı bölümünde  İngilizce eğitim alarak devam etmiş ve  tamamlamıştır. Kariyerine MimarK ile başlayıp Güveli Mimarlıkta devam ederken kendine ait IDesign markasını  kurarak  iç mimarlık alanında kendi kariyerine özgün tasarımlarıyla devam etmektedir.",
  homePage_projectMiniTitle_1: "Proje",
  homePage_projectMiniTitle_2: "Şirket",
  homePage_projectMiniTitle_3: "Yıl",
  project1Text: "konut çalışması",
  project2Text: "konut çalışması",
  project3Text: "konut çalışması",
  project4Text: "iç dizayn çalışması",
  project5Text: "dış cephe peyzaj çalışması",
  project6Text: "iç dizayn çalışması",
  project7Text: "iç dizayn çalışması",
  project8Text: "iç dizayn çalışması",
  project9Text: "iç dizayn çalışması",
  project10Text: "dış cephe tasarım",
  projectButton: "Tüm Çalışmaları Gör",
  yorumlar1:
    "İlayda hanımın göstermiş olduğu üçte tasarım ile gerçek proje arasında hiçbir fark bulamıyorum gerçekten söylediklerini birebir şekilde uygulaması çok güzel.",
  yorumlar2:
    "İlayda hanıma çok teşekkür ederim kafamdaki düşüncelerimi birebir projeye yansıtabilmesi gerçekten çok güzel",
  yorumlar3:
    "Bana verilen anahtar teslim tarihi ve kullanılan ürünler ile ilgili hiçbir sorun yaşanmadı gerçekten işini iyi yapabilen biriyle çalışmaktan çok memnun kaldım",
  yorumlar4:
    "İlayda hanımın göstermiş olduğu üçte tasarım ile gerçek proje arasında hiçbir fark bulamıyorum gerçekten söylediklerini birebir şekilde uygulaması çok güzel.",
  yorumlar5:
    "İlayda hanıma çok teşekkür ederim kafamdaki düşüncelerimi birebir projeye yansıtabilmesi gerçekten çok güzel",
  contactInfo: {
    website: "Web Sitesi",
    email: "E-Posta",
    phone: "Telefon",
    address: "Adres",
    sign1: "© 2021 IDESIGN. Tüm hakları saklıdır",
    sign2: "Bu websitesi agecnyofmenn.com tarafından yapılmıştır",
  },
  aboutPage: {
    mission: "Misyonumuz",
    missionText:
      "Organizasyonel ruhumuz, tasarımın mekanla tutku ve aşkla buluştuğu yerde iyi mimarinin gerçekleştiğine inanır",
    aboutTitle: {
      title1: "mimarlık",
      title2: "ait olduğumuz yer",
    },
    aboutText:
      "İlayda Balcı, 2016-2018 yılları arasında Kütahya Dumlupınar Üniversitesinde Güzel Sanatlar Meslek Yüksekokulunda İç  Mekan Tasarımı eğitimi almış daha sonra lisansına İstanbul Medipol Üniversitesi- Mimarlık Fakültesinde  İç Mimarlık ve Çevre Tasarımı bölümünde  İngilizce eğitim alarak devam etmiş ve  tamamlamıştır. Kariyerine MimarK ile başlayıp Güveli Mimarlıkta devam ederken kendine ait IDesign markasını  kurarak  iç mimarlık alanında kendi kariyerine özgün tasarımlarıyla devam etmektedir.",
    aboutText2: {
        span1: "Her gün",
        span2: "yeni hayallerle",
        p: " gelen insanlarla tanışıyoruz ve ",
        span3: "tüm çabamız onların hayallerini gerçekleştirmek",
    }
  },
  projectPage: {
    title: {
        span1: "P",
        span2: "R",
        span3: "O",
        span4: "J",
        span5: "E",
        span6: "L",
        span7: "E",
        span8: "R",
    },
    review: "İncele",
  },

  homePage_slider: {
    slide1_title: "Modern Tasarım",
    slide1_text: "Mekanlarınızı modern tasarımlarla buluşturun, geleceğe adım atın.",
    slide2_title: "Estetik Tasarım",
    slide2_text: "Estetik tasarımla yaşam alanlarınızı dönüştürün, her gününüz özel olsun.",
    slide3_title: "Konfor",
    slide3_text: "Estetik ve konforu bir arada buluşturun, evinizdeki yaşamı mükemmelleştirin",
  },

  projectsData : {
    project1: {
      desc: "Konut çalışması",
    },
    project2: {
      desc: "Konut çalışması",
    },
    project3: {
      desc: "Konut çalışması",
    },
    project4: {
      desc: "İç dizayn çalışması",
    },
    project5: {
      desc: "Dış cephe&peyzaj çalışması",
    },
    project6: {
      desc: "İç dizayn çalışması",
    },
    project7: {
      desc: "İç dizayn çalışması",
    },
    project8: {
      desc: "İç dizayn çalışması",
    },
    project9: {
      desc: "İç dizayn çalışması",
    },
    project10: {
      desc: "Dış cephe tasarım",
    },
  }
};

export default lang_tr;
